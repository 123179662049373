import { Injectable, inject } from '@angular/core';
import { Observable, Subject, first, map, repeat, shareReplay, switchMap, tap } from 'rxjs';

import { CreateAlternateEstimateData, OverviewAlternateEstimate } from '@dartsales/common/core/models/estimate/alternate-estimate';
import { TermMap } from '@dartsales/common/core/models/term-map';

import { EstimateService } from '../models/estimate-services/estimate-service';
import { Estimate, EstimateId } from '../models/estimate/estimate';
import { TermSummary } from '../models/estimate-services/term-summary';
import { FetchListOptions } from '../models/list-utilities/fetch-list-options';
import { Project } from '../models/project/project';
import { SortDirection } from '../enums/sort-direction';

import { AlternateEstimateApiService } from './api/alternate-estimate-api.service';
import { BaseEstimateApiService } from './api/base-estimate-api.service';
import { EstimateServicesApiService } from './api/estimate-services-api.service';
import { ProjectNavigationService } from './project-navigation.service';
import { ProjectLayoutService } from './project-layout.service';

/** Service with alternate estimates for the current project. */
@Injectable()
export class ProjectAlternateEstimatesService {

  private readonly alternateEstimateApiService = inject(AlternateEstimateApiService);

  private readonly baseEstimateApiService = inject(BaseEstimateApiService);

  private readonly estimateServicesApiService = inject(EstimateServicesApiService);

  private readonly projectNavigationService = inject(ProjectNavigationService);

  private readonly refreshSubject = new Subject<void>();

  private readonly projectId$ = inject(ProjectLayoutService).projectId$;

  /** Alternate estimates. */
  public readonly estimates$ = this.createAlternateEstimatesStream(this.projectId$);

  /** Number of project's alternate estimates from base estimate. */
  public readonly alternateEstimatesCounter$ = this.createAlternateEstimatesCounterStream(this.projectId$);

  /** Number of project's alternate estimates from list. */
  public readonly listedAlternateEstimatesCount$ = this.getAlternateEstimatesCountStream(this.projectId$);

  /**
   * Add a new alternate estimate to project.
   * @param data Data required to create a new alternate estimate.
   */
  public addToProject(data: CreateAlternateEstimateData): Observable<Estimate['id']> {
    return this.projectId$.pipe(
      switchMap(id => this.alternateEstimateApiService.create(id, data)),
      tap(() => this.refresh()),
    );
  }

  /** Get new alternate name. */
  public getNewAlternateName(): Observable<string> {
    return this.projectId$.pipe(
      switchMap(id => this.alternateEstimateApiService.getNewAlternateName(id)),
    );
  }

  /**
   * Update alternate estimates order.
   * @param estimates List of alternate estimates to update.
   */
  public updateAlternateEstimateOrder(estimates: OverviewAlternateEstimate[]): Observable<void> {
    return this.alternateEstimateApiService.updateOrder(estimates);
  }

  /**
   * Delete an alternate estimate.
   * @param id Alternate estimate ID.
   */
  public remove(id: Estimate['id']): Observable<void> {
    return this.alternateEstimateApiService.deleteById(id).pipe(
      tap(() => {
        this.refreshSubject.next();
        this.projectNavigationService.navigateToDashboard();
      }),
    );
  }

  /**
   * Convert term to a new alternate estimate.
   * @param termId Term id.
   */
  public convertFromTerm(termId: EstimateService['termId']): Observable<OverviewAlternateEstimate['id']> {
    return this.estimateServicesApiService.createAlternateEstimateFromTerm(termId).pipe(
      tap(() => this.refresh()),
    );
  }

  /**
   * Convert all terms to alternate estimates.
   * @param estimateId Estimte ID.
   * @param termIds Term ids.
   */
  public convertFromAllTerms(
    estimateId: EstimateId,
    termIds: readonly TermSummary['id'][],
  ): Observable<TermMap[]> {
    return this.estimateServicesApiService.createAlternateEstimatesFromAllTerms(estimateId, termIds).pipe(
      tap(() => this.refresh()),
    );
  }

  /** Refresh alternate estimates. */
  public refresh(): void {
    this.refreshSubject.next();
  }

  private createAlternateEstimatesStream(projectId$: Observable<Estimate['id']>): Observable<OverviewAlternateEstimate[]> {
    return projectId$.pipe(
      switchMap(id => {
        const listOptions: FetchListOptions<Project['id']> = { filter: id, sort: [{ field: 'order', direction: SortDirection.ASC }] };
        return this.alternateEstimateApiService.getList(id, listOptions);
      }),
      first(),
      map(page => [...page.items]),
      repeat({ delay: () => this.refreshSubject }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private createAlternateEstimatesCounterStream(projectId$: Observable<Estimate['id']>): Observable<number> {
    return projectId$.pipe(
      switchMap(id => this.baseEstimateApiService.getAlternatesCounter(id)),
      first(),
      repeat({ delay: () => this.refreshSubject }),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
  }

  private getAlternateEstimatesCountStream(projectId$: Observable<Estimate['id']>): Observable<number> {
    return projectId$.pipe(
      switchMap(id => this.alternateEstimateApiService.getList(id)),
      first(),
      map(page => page.pagination.totalCount),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
