import { BasePricing, BasePricingInitArgs } from '@dartsales/common/core/models/pricing/base-pricing';

/** Base pricing for final estimate. */
export class FinalEstimatePricing extends BasePricing {

  /** Labor hours. */
  public readonly laborHours: number;

  public constructor(data: FinalEstimatePricingInitArgs) {
    super(data);
    this.laborHours = data.laborHours;
  }
}

/** Final estimate pricing initial arguments. */
export type FinalEstimatePricingInitArgs = BasePricingInitArgs &
  Pick<FinalEstimatePricing, 'laborHours'>;
