import { Injectable, inject } from '@angular/core';
import { Subject, Observable, map, shareReplay, switchMap, repeat, first } from 'rxjs';

import { FinalEstimate } from '@dartsales/common/core/models/estimate/final-estimate/final-estimate';
import { Project } from '@dartsales/common/core/models/project/project';

import { FinalEstimateStatus } from '../models/estimate/final-estimate/final-estimate-status';
import { FetchListOptions } from '../models/list-utilities/fetch-list-options';
import { SortDirection } from '../enums/sort-direction';

import { FinalEstimateApiService } from './api/final-estimate-api.service';
import { ProjectLayoutService } from './project-layout.service';

/** Service with final estimates for the current project. */
@Injectable()
export class ProjectFinalEstimatesService {

  private readonly finalEstimateApiService = inject(FinalEstimateApiService);

  private readonly refreshSubject = new Subject<void>();

  private readonly projectId$ = inject(ProjectLayoutService).projectId$;

  /** Final estimates. */
  public readonly estimates$ = this.createFinalEstimatesStream(this.projectId$);

  /** Final awarded estimate. */
  public readonly awardedEstimate$ = this.createFinalAwardedEstimateStream(this.estimates$);

  /** Number of included alternates. */
  public readonly includedAlternatesCount$ = this.createIncludedAlternatesCountStream(this.awardedEstimate$);

  /** Refresh final estimates. */
  public refresh(): void {
    this.refreshSubject.next();
  }

  private createFinalEstimatesStream(projectId$: Observable<Project['id']>): Observable<FinalEstimate[]> {
    // We don't use 'shareReplay' here intentionally. We want to request finalEstimate from server every time.
    return projectId$.pipe(
      switchMap(id => {
        const listOptions: FetchListOptions<Project['id']> = {
          filter: id,
          sort: [{ field: 'order', direction: SortDirection.ASC }],
        };

        return this.finalEstimateApiService.getList(id, listOptions);
      }),
      first(),
      map(page => [...page.items]),
      repeat({ delay: () => this.refreshSubject }),
    );
  }

  private createFinalAwardedEstimateStream(finalEstimates$: Observable<FinalEstimate[]>): Observable<FinalEstimate | null> {
    return finalEstimates$.pipe(
      map(estimates => estimates.find(estimate => estimate.status === FinalEstimateStatus.Awarded) ?? null),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private createIncludedAlternatesCountStream(awardedEstimate$: Observable<FinalEstimate | null>): Observable<number> {
    return awardedEstimate$.pipe(
      map(estimate => estimate?.includedAlternateEstimates.length ?? 0),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
