import { Injectable, inject } from '@angular/core';
import { Subject, Observable, map, shareReplay, switchMap, first, repeat, tap } from 'rxjs';

import { CreateChangeOrder, ChangeOrderId, PreviewChangeOrder } from '../models/estimate/change-order/change-order';
import { SortDirection } from '../enums/sort-direction';
import { FetchListOptions } from '../models/list-utilities/fetch-list-options';
import { Project } from '../models/project/project';

import { ProjectLayoutService } from './project-layout.service';
import { ChangeOrdersApiService } from './api/change-orders-api.service';

/** Service with change orders for the current project. */
@Injectable()
export class ProjectChangeOrdersService {

  private readonly changeOrdersApiService = inject(ChangeOrdersApiService);

  private readonly projectLayoutService = inject(ProjectLayoutService);

  private readonly refreshSubject = new Subject<void>();

  /** List of change orders for the project. */
  public readonly changeOrders$ = this.createChangeOrdersStream();

  /** Whether the project has change orders. */
  public readonly hasChangeOrders$ = this.createHasChangeOrdersStream();

  /** Refresh change orders. */
  public refresh(): void {
    this.refreshSubject.next();
  }

  /**
   * Add a new change order to the project.
   * @param id Change order ID.
   * @param data Data required to create a new change order.
   */
  public addToProject(id: ChangeOrderId, data: CreateChangeOrder): Observable<ChangeOrderId> {
    return this.changeOrdersApiService.create(id, data).pipe(
      tap(() => this.refreshSubject.next()),
    );
  }

  /**
   * Delete a change order.
   * @param id Change order ID.
   */
  public remove(id: ChangeOrderId): Observable<void> {
    return this.changeOrdersApiService.deleteById(id).pipe(
      tap(() => this.refreshSubject.next()),
    );
  }

  /**
   * Update change orders list order.
   * @param estimates List of change orders to update.
   */
  public updateOrder(estimates: readonly PreviewChangeOrder[]): Observable<void> {
    return this.changeOrdersApiService.updateOrder(estimates);
  }

  private createChangeOrdersStream(): Observable<PreviewChangeOrder[]> {
    return this.projectLayoutService.projectId$.pipe(
      switchMap(id => {
        const listOptions: FetchListOptions<Project['id']> = {
          filter: id,
          sort: [{ field: 'order', direction: SortDirection.ASC }],
        };

        return this.changeOrdersApiService.getList(id, listOptions);
      }),
      first(),
      map(page => [...page.items]),
      repeat({ delay: () => this.refreshSubject }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private createHasChangeOrdersStream(): Observable<boolean> {
    return this.changeOrders$.pipe(
      map(changeOrders => changeOrders.length > 0),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
